import {Nav, Navbar, NavbarBrand, NavItem, NavLink} from "reactstrap";

export default function DashboardNavigationHeader(props: any) {

    const toggle = (tab: number) => {
        props.toggle(tab)
    }

    return (
        <div>
            <Navbar>
                <NavbarBrand className={"brand-text"} style={{color: "#3F85FC"}}>
                    Stabl Pay
                </NavbarBrand>
                <Nav pills className="me-auto">
                    <NavItem>
                        <NavLink className={props.dashboard ? "active" : ""}
                                 href="#" onClick={() => toggle(1)}
                                 style={{backgroundColor: (props.dashboard ? "#E9F5FF" : "")}}>
                            <div className={props.dashboard ? "dash-tab-active-text" : "dash-tab-inactive-text"}>
                                Dashboard
                            </div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={!props.dashboard ? "active" : ""} href="#"
                                 onClick={() => toggle(2)}
                                 style={{backgroundColor: (!props.dashboard ? "#E9F5FF" : "")}}>
                            <div className={!props.dashboard ? "dash-tab-active-text" : "dash-tab-inactive-text"}>
                                Employees
                            </div>
                        </NavLink>
                    </NavItem>
                </Nav>
                <div className={"header-company-name"}>
                    {props.companyName}
                </div>
            </Navbar>
        </div>
    )
}