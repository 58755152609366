import {backendApiClient} from "./axios-helper";

class TimeAndAttendanceProviderService {

    async getTimeAndAttendanceProviders() {
        const response = await backendApiClient.get('/time_and_attendance_providers')
        return response.data
    }
}

export default new TimeAndAttendanceProviderService()