import React from 'react'
import {Col, Row, Spinner} from "reactstrap";

function Loading() {
    return (
        <>
            <Row className={"mt-5"}>
                <Col className={"mt-5 py-5 text-center"}>
                    <Spinner type="grow" style={{color: '#FF8552', width: "5rem", height: "5rem"}}>
                    </Spinner>
                </Col>
            </Row>
        </>
    )
}

export default Loading
