import {Col, Row} from "reactstrap";
import React from "react";

export default function Footer() {
    const currentYear = new Date().getFullYear()

    const privacyPolicyURL = process.env.REACT_APP_PRIVACY_POLICY_URL
    const glbaProlicyURL = process.env.REACT_APP_GLBA_POLICY_URL
    const termsOfServiceURL = process.env.REACT_APP_TERMS_OF_SERVICE_URL

    return (
        <>
            <Row className={"registration-footer py-4"}>
                <Col className={""}>
                    <Row className={""}>
                        <Col xs={6} className={"copyright text-left p-0"}>
                            © {currentYear} Stabl Pay, All Rights Reserved.
                        </Col>
                        <Col xs={2}/>
                        <Col xs={4} className={"copyright text-end"}>
                            <Row>
                                <Col xs={3}>
                                    <a href={privacyPolicyURL} className={"footer-link"}>Privacy Policy</a>
                                </Col>
                                <Col xs={4}>
                                    <a href={glbaProlicyURL} className={"footer-link"}>Consumer Privacy Notice</a>
                                </Col>
                                <Col xs={3}>
                                    <a href={termsOfServiceURL} className={"footer-link"}>Terms of Service</a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}