/**
 * Checks if a pay period is currently ongoing.
 *
 * @param payPeriod
 * @param utcOffset
 */
export const isCurrentPayPeriod = (payPeriod: PayPeriod, utcOffset: number) => {
    const today = getDateWithUTCOffset(utcOffset).getTime()
    const periodStart = Date.parse(payPeriod.start)
    const periodEnd = Date.parse(payPeriod.end)
    return today <= periodEnd && today >= periodStart
}

/**
 * Gets current date for the timezone specified with hours offset.
 *
 * @param hoursOffset
 */
function getDateWithUTCOffset(hoursOffset: number) {
    const now = new Date()
    const nowTimestamp = now.getTime();
    const offsetInMilliseconds = hoursOffset * 1000 * 60 * 60
    return new Date(nowTimestamp + offsetInMilliseconds)
}

/**
 * Turns dateString into YYYY-MM-dd format
 *
 * @param dateString
 */
export const simpleFormat = (dateString: any) => {
    const date = new Date(dateString)
    return date.toISOString().split('T')[0];
}

/**
 * Turns dateString into YYYY/MM/dd format
 *
 * @param dateString
 */
export const otherSimpleFormat = (dateString: any) => {
    const m = new Date(dateString)
    return m.getUTCFullYear() + "/" +
        ("0" + (m.getUTCMonth() + 1)).slice(-2) + "/" +
        ("0" + m.getUTCDate()).slice(-2)
}

/**
 * Turns dateString into MM/dd/YYYY format
 *
 * @param dateString
 */
export const getFormattedDate = (dateString: any) => {
    const date = new Date(dateString)
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return month + '/' + day + '/' + year;
}