import {Col, Row} from "reactstrap";
import React, {useState} from "react";
import EmployeeEditForm from "./employee-edit-form.component";
import {formatTelUS} from "../../utils/phone-number.utils";
import CompanyService from "../../services/company.service";
import {Link} from "react-router-dom";

export default function Employee(props: any) {

    const [edit, setEdit] = useState(false)
    const [employee, setEmployee] = useState(props.employee)

    const onSubmit = async (data: any) => {
        await CompanyService.updateCompanyEmployee(employee.id, data)
        setEdit(false)
        props.onEmployeeUpdate()
    }

    const toggle = () => {
        setEmployee(props.employee)
        setEdit(!edit)
    }

    return (
        <>
            {edit ? (
                <>
                    <EmployeeEditForm employee={props.employee} onSubmit={onSubmit} onCancel={toggle}/>
                </>
            ) : (
                <>
                    <Row className={"my-2 mx-0 p-0 payout-reports-advance-text"}>
                        <Col
                            xs={2}
                            className={"text-left p-0"}>
                            <div className={"text-truncate"}>
                                <i className="bi bi-person-fill"
                                   />&nbsp;&nbsp;{props.employee.full_name}
                            </div>
                        </Col>
                        <div className={"employee-list-column-spacer"}/>
                        <Col
                            xs={3}
                            className={"text-left p-0 "}>
                            <div className={"text-truncate"}>
                                {props.employee.email}
                            </div>
                        </Col>
                        <div className={"employee-list-column-spacer"}/>
                        <Col
                            xs={2}
                            className={"text-left p-0 "}>
                            {!!(props.employee.phone_number) ? (
                                <div className={"text-truncate"}>{formatTelUS(props.employee.phone_number)}</div>
                            ) : (
                                <div className={"text-danger flex-nowrap"}
                                     style={{fontSize: ".85vw", whiteSpace: "nowrap"}}><i
                                    className="bi bi-exclamation-circle"/>&nbsp;&nbsp;Missing Number</div>
                            )}

                        </Col>
                        <div className={"employee-list-column-spacer"}/>
                        <Col xs={1} className={"text-end px-2"}>
                            {!!(props.employee.phone_number) ? (
                                <Link to={"#"} onClick={toggle} style={{textDecoration: 'none', color: 'black'}}>
                                    Edit
                                </Link>
                            ):(
                                <Link to={"#"} onClick={toggle} style={{textDecoration: 'none', color: 'rgba(220,53,69)'}}>
                                    Edit
                                </Link>
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}