import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import React from "react";

export default function CardTemplate(props: any) {

    return (
        <Card className="card-stats mb-4 mb-xl-0" style={{borderColor: "#E5E7EB"}}>
            <CardBody className={"my-4 my-md-4 my-lg-4 my-xl-4"}>
                <Row className={"mb-2 mb-md-2 mb-lg-2 mb-xl-2"}>
                    <Col xl={12}>
                        <div
                            className="mb-1 text-center">
                            <i className={props.iconClass}/>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Row>
                            <CardTitle
                                tag="h5"
                                className="card-stats-title mb-1"
                            >
                                {props.title}
                            </CardTitle>
                        </Row>
                        <Row>
                            <span className="card-stats-value">
                            {props.value}
                        </span>
                        </Row>
                    </Col>
                </Row>
            </CardBody>
        </Card>


    )
}