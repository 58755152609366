export enum RegistrationFormState {
    INFORMATION_PAGE = 'INFORMATION_PAGE',
    SYSTEMS_CHECK_PAGE_ONE = 'SYSTEMS_CHECK_PAGE_ONE',
    SYSTEMS_CHECK_PAGE_TWO = 'SYSTEMS_CHECK_PAGE_TWO',
    SYSTEMS_TIME_AND_ATTENDANCE = 'SYSTEMS_TIME_AND_ATTENDANCE',
    CONFIRMATION_PAGE = 'CONFIRMATION_PAGE',
    WAIT_LIST_PAGE = 'WAIT_LIST_PAGE',
    SELF_SERVE_OPTION_PAGE = 'SELF_SERVE_OPTION_PAGE',
    CALENDAR_PAGE = 'CALENDAR_PAGE',
    SCHEDULED_COMPLETE_PAGE = 'SCHEDULED_COMPLETE_PAGE',
    SUCCESS_PAGE = 'SUCCESS_PAGE'
}