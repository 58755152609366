import { Col, Container, Row } from "reactstrap";
import NotificationBanner from "../universal/notification-banner.component";
import React from "react";
import EmployeeListCard from "../universal/employee-list-card.component";

export default function EmployeeTab(props: any) {

    const onUpdate = () => {
        props.onUpdate()
    }

    return (
        <>
            <Container fluid className={""}>
                <div className="header-body">
                    {/* Current Period */}
                    <Row className={"mt-5 mb-5"}>
                        <Col lg="4" xl="4">
                            <Container className="mb-4 mb-xl-0">
                                <Row>
                                    <div className="col mt-4">
                                        <p className="current-period-header mb-0">
                                            Current Employees
                                        </p>
                                    </div>
                                    <p className="mt-3 mb-0 current-period-text">List of employees on record</p>
                                </Row>
                            </Container>
                        </Col>
                        <Col lg="8" xl="8">
                        </Col>
                    </Row>
                </div>
                <div>
                    {props.missingData && (
                        <Row>
                            <NotificationBanner
                                iconClass={"fa-solid fa-bullhorn"}
                                className={"employee-missing-notification"}
                                alertTopic={"Missing Data"}
                                alertMessage={"We're missing some of your employees' data."}
                                resolution={false}
                            />
                        </Row>
                    )}
                </div>
                <div>
                    <Row className={"border-bottom mt-5 px-0"}>
                        <Col lg="4" xl="4">
                            <Container className={"mt-5 px-0"}>
                                <div className={"text-left payout-reports-header px-0"}>Employees</div>
                            </Container>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row>
                        <div className={"px-0"}>
                            <EmployeeListCard employees={props.employees} onUpdate={onUpdate}/>
                        </div>
                    </Row>
                </div>
            </Container>
        </>
    )
}