/**
 * Remove non-numbers from string
 *
 * @param {string} str     - String you wish to remove non-numbers from
 * @param {*} [noValue=''] - What returns if there is no string.
 * @returns {string|*}     - String without numbers or noValue.
 */
export function onlyStringNums(str: string, noValue = '') {
    return (str && str.replace(/[^\d]/g, '')) || noValue
}

/**
 * Format E.164 US phone number.
 *
 * @param {string} telString - US phone number string in E.164 format (has +1 country code)
 * @returns {string}         - Formatted US phone number.
 */
export function formatTelUS(telString: string) {
    if (!telString) return ''
    const telNums = onlyStringNums(telString).substring(1) // removes +1 country code
    const numNums = telNums.length
    if (numNums <= 3) return `(${telNums}`
    if (numNums <= 6) return `(${telNums.slice(0, 3)}) ${telNums.slice(3, 6)}`
    return `(${telNums.slice(0, 3)}) ${telNums.slice(3, 6)}-${telNums.slice(
        6,
        10
    )}`
}

/**
 * Parse formatted US phone number to E.164.
 *
 * @param {string} telString - Formatted telephone string.
 * @returns {string|null}    - Telephone numbers only, limit 10 with +1 US code, or null if empty for Redux/API.
 */
export function parseTelUS(telString: string) {
    return (telString && `+1${onlyStringNums(telString).slice(0, 10)}`) || null
}

export function normalizeInput(value: any, previousValue: any) {
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {

        // returns: "x", "xx", "xxx"
        if (cvLength < 4) return currentValue;

        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

        // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
}
