import {adapterApiClient} from "./axios-helper";

class PinwheelService {

    async getPinwheelLink(linkType: string) {
        const response = await adapterApiClient.get(`/clover/company/pinwheel-link?linkType=${linkType}`)
        return response.data.link_token
    }

    async validatePinwheelData(payload: any) {
        return await adapterApiClient.post('/clover/registration/validate-pinwheel-data', payload)
    }

}

export default new PinwheelService()