import { Col, Container, Row } from "reactstrap";
import CardTemplate from "../universal/card-template.component";
import NotificationBanner from "../universal/notification-banner.component";
import PayoutReport from "./payout-report.component";
import React from "react";

export default function DashboardTab(props: any) {

    const toggleEmployeeTab = () => {
        props.toggle(2)
    }

    return (
        <>
            <Container fluid>
                <div className="header-body">
                    {/* Current Period */}
                    <Row className={"mt-5 mb-5"}>
                        <Col lg="4" xl="4">
                            <Container className="mb-4 mb-xl-0">
                                <Row>
                                    <div className="col mt-4">
                                        <p className="current-period-header mb-0">
                                            Current Period
                                        </p>
                                    </div>
                                    <p className="mt-3 mb-0 current-period-text">
                                        Activity data for the current pay period
                                    </p>
                                </Row>
                            </Container>
                        </Col>
                        <Col lg="8" xl="8">
                            <Row>
                                <Col lg="4" xl="4" className={""}>
                                    <CardTemplate
                                        title={"Employees"}
                                        value={props.currentPeople}
                                        iconClass={"bi bi-people stats-card-icon"}
                                    />
                                </Col>
                                <Col lg="4" xl="4" className={""}>
                                    <CardTemplate
                                        title={"Advances"}
                                        value={props.currentCount}
                                        iconClass={"bi bi-credit-card stats-card-icon"}
                                    />
                                </Col>
                                <Col lg="4" xl="4" className={""}>
                                    <CardTemplate
                                        title={"Total Paid"}
                                        value={props.currentTotal}
                                        iconClass={"bi bi-currency-dollar stats-card-icon"}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div>
                    {props.missingData && (
                        <Row>
                            <NotificationBanner
                                className={"employee-missing-notification"}
                                alertTopic={"Missing Data"}
                                alertMessage={"We're missing some of your employees' data."}
                                resolution={true}
                                toggleEmployeeTab={toggleEmployeeTab}
                            />
                        </Row>
                    )}
                </div>
                <div>
                    <Row className={"mb-3"}>
                        <Col lg="4" xl="4">
                            <Container className={"mt-5 px-0"}>
                                <div className={"payout-reports-header"}>Payout Reports</div>
                            </Container>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row>
                        <div className={"px-0"}>
                            <PayoutReport
                                advancePeriods={props.advancePeriods}
                                utcOffset={props.companyUtcOffset}
                                advancesPresent={(props.advancePeriods && props.advancePeriods.length !== 0)}/>
                        </div>

                    </Row>
                </div>
            </Container>
        </>
    )
}