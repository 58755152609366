import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import EmployeeListCard from "../universal/employee-list-card.component";
import CompanyService from "../../services/company.service";
import Loading from "../layout/loading.component";
import RegistrationInfoPanel from "../layout/registration-info-panel.component";
import { toast } from "react-toastify";


export default function CommunicationsConfirmation(props: any) {

    const [employees, setEmployees] = useState<any>([])
    const [isBusy, setIsBusy] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [hasMissingPhoneNumbers, setHasMissingPhoneNumbers] = useState(true)

    const privacyPolicyURL = process.env.REACT_APP_PRIVACY_POLICY_URL
    const termsOfServiceURL = process.env.REACT_APP_TERMS_OF_SERVICE_URL

    const messageText = <div>When it comes to onboarding your team, we do the work for you. Through email and SMS
        outreach, we will introduce the service to them and walk them through their enrollment process.
        <br/><br/>It’s fast for them (less than 5 minutes) and effortless for you. We will be reaching out to your team
        using the contact information to the right, so let’s take a moment to verify their contact info so no one gets
        left out.
        <br/><br/>If there is missing info to the right that you don’t have right now, you will be able to make those
        edits from your Stabl dashboard after this sign up process completes.</div>

    const subText = <div>By checking this box, I acknowledge and agree to let Stabl reach out to my team using the
        contact
        information available. This includes the information above and will be updated periodically through the
        integration
        with my payroll system. I further acknowledge that I agree to Stabl’s&nbsp;
        <a href={termsOfServiceURL}>Terms of Service</a> and&nbsp;
        <a href={privacyPolicyURL}>Privacy</a> Policies.</div>


    async function checkMissingPhoneNumbers(employeeResult: any) {
        for (const employee of employeeResult.data.employee_list) {
            if (employee.phone_number === null || employee.phone_number === "") {
                setHasMissingPhoneNumbers(true);
                break; // exit the loop entirely
            } else {
                setHasMissingPhoneNumbers(false);
            }
        }
    }

    useEffect(() => {
        async function initializeEmployeeList() {
            setIsBusy(true)
            await CompanyService.initializeCompanyEmployeeList()
            let employeeResult = await CompanyService.getCompanyEmployees()
            // Set a boolean if any employees have missing phone numbers
            await checkMissingPhoneNumbers(employeeResult);

            setEmployees(employeeResult.data.employee_list)
            setIsBusy(false)
        }

        initializeEmployeeList()
    }, [])


    const onGoBack = () => {
        props.onGoBack()
    }

    const onSubmit = (e: React.SyntheticEvent) => {

          if (hasMissingPhoneNumbers) {
                toast.error("Please update all phone numbers before continuing.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored"
                })
              e.preventDefault()
            } else {
              e.preventDefault()
              props.onComplete()
          }
    }

    const onEmployeeUpdate = async () => {
        const employeesResult = await CompanyService.getCompanyEmployees()
        await checkMissingPhoneNumbers(employeesResult);
        setEmployees(employeesResult.data.employee_list)
        setIsBusy(false)
    }

    return (
        <>
            <Col>
                <Row className={"mb-5"}>
                    {isBusy ? (
                        <>
                            <Row className={"mt-5 mb-3"}>
                                <div className="mt-5 mb-5">
                                    <Loading/>
                                </div>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Col xs={4} className={"register-center-margin"}>
                                <RegistrationInfoPanel
                                    infoText={"Congratulations, your connection looks good! Now let your team know."}
                                    subInfoText={messageText}
                                    smallTitleText={true}/>
                            </Col>
                            <Col xs={1} className={""}/>
                            <Col xs={7} className={"registration-form-container"}>
                                <div className={"my-4 mx-sm-1 mx-md-1 mx-lg-2 mx-xl-3"}>
                                    <div className={"text-left my-3"}>
                                        <p className="reg-employee-list-header mt-3">
                                            Current Employees
                                        </p>
                                    </div>
                                    <EmployeeListCard
                                        employees={employees}
                                        onUpdate={onEmployeeUpdate}/>
                                </div>
                                <Row className={"mx-sm-1 mx-md-1 mx-lg-2 mx-xl-3"}>
                                    <Col className={"mb-4"}>
                                        <Row className={"my-1"}>
                                            <Col className={"text-end"} sm={1}>
                                                <Input type="checkbox"
                                                       onChange={(e) => setIsChecked(e.target.checked)}/>
                                            </Col>
                                            <Col sm={11} className={"text-left text-muted"}>
                                                <Label check>
                                                    <small>
                                                        {subText}
                                                    </small>
                                                </Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className={"my-0 mx-sm-1 mx-md-1 mx-lg-2 mx-xl-3"}>
                                    <Form className={"mb-4"} onSubmit={onSubmit}>
                                        <Row style={{justifyContent: "end"}}>
                                            <div style={{width: "200px"}} className={"p-0"}>
                                                <Row className={"m-0"} style={{justifyContent: "center"}}>
                                                    <div style={{height: "38px", width: "80px"}} className={"mx-1"}>
                                                        <Button className={"back-button"} onClick={onGoBack}>
                                                            Back
                                                        </Button>
                                                    </div>
                                                    <div style={{height: "38px", width: "100px"}} className={""}>
                                                        <Button className={"registration-save-continue-button"}
                                                                disabled={!isChecked}>
                                                            Continue
                                                        </Button>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Row>
                                    </Form>
                                </Row>
                            </Col>
                        </>
                    )}
                </Row>
            </Col>
        </>
    )
}