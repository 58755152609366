import {Col, Row} from "reactstrap";
import React from "react";
import {getFormattedDate} from "../../utils/date.utils";


export default function Advance(props: any) {

    return (
        <>
            <Row className={"mt-3 payout-reports-advance-text"}>
                <Col
                    xs={2}
                    className={"text-left"}>
                    <div>
                        <i className={"fa-regular fa-circle-user"}/>&nbsp;&nbsp;{props.advance.employeeName}
                    </div>
                </Col>
                <Col
                    xs={3}
                    className={"text-left"}>
                    <div>
                        {getFormattedDate(props.advance.date)}
                    </div>
                </Col>
                <Col
                    className={"text-right"}>
                    <div>
                        ${props.advance.amount}
                    </div>
                </Col>
                <Col
                    className={"text-right"}>
                    <div>
                        ${props.advance.fee}
                    </div>
                </Col>
                <Col
                    className={"text-right"}>
                    <div>
                        ${props.advance.total}
                    </div>
                </Col>
            </Row>
        </>
    )
}