import {Button, Col, Form, Progress, Row} from "reactstrap";
import React, {useCallback, useState} from "react";
import PinwheelService from "../../services/pinwheel.service"
import PinwheelModal from '@pinwheel/react-modal'

export default function SystemsCheckFormPinwheel(props: any) {

    const [linkToken, setLinkToken] = useState('')
    const [open, setOpen] = useState(false)
    const [success, setSuccess] = useState(false)

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
    }

    const getLinkToken = useCallback(async () => {
            try {
                const token = await PinwheelService.getPinwheelLink("payroll")
                setLinkToken(token)
                setOpen(true)
                return undefined
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        },
        []
    )

    const onSuccess = useCallback(async (result: any) => {
        try {
            localStorage.setItem('test_payroll_token', result as string)
            setOpen(false)
            setSuccess(true)
            return undefined
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }, [])

    const onLogin = useCallback(async (result: any) => {
        console.log('Successful login')
    }, [])

    const onError = useCallback(async (result: any) => {
    }, [])

    const handleExit = useCallback(async () => {
            setOpen(false)
        },
        []
    )

    return (
        <>
            <Row>
                <div className={"mb-5"}>
                    <Progress
                        value={66}
                    />
                </div>
            </Row>
            <Row>
                <div className={"mb-5"}>
                    <Row>
                        <div>
                            <h1>
                                Let's Start By Linking Your Payroll Provider...
                            </h1>
                        </div>
                    </Row>
                    <Row>
                        <Row>
                            <div className={"mb-5"}>
                                <h5>
                                    By logging in with credentials of an employee (a non-admin account), we can
                                    check the connection.
                                </h5>
                            </div>
                        </Row>
                    </Row>
                </div>
            </Row>
            <Row>
                <Form className={"mt-5 mb-5"} onSubmit={onSubmit}>

                    {success ? (
                        <Row className={"mb-5"}>
                            <div className={"mb-5"}>
                                <h1>
                                    Congrats! Your Connection Looks Good!
                                </h1>
                            </div>
                        </Row>
                    ) : (
                        <Row className={"mt-5 mb-5"}>
                            <Col>
                                <Button className={"mt-5 mb-5"} onClick={getLinkToken}>
                                    Connect
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <Row className={"mt-5 mb-5"}>
                        <Col>
                            <Button className={"mt-5 mb-5"} onClick={props.onGoBack}>
                                {"< Back"}
                            </Button>
                            <PinwheelModal
                                linkToken={linkToken}
                                open={open}
                                onSuccess={onSuccess}
                                onLogin={onLogin}
                                onExit={handleExit}
                                onError={onError}
                            />
                        </Col>

                        <Col>
                            {success && (
                                <Button className={"mt-5 mb-5"}>
                                    {"Next >"}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Form>
            </Row>
        </>
    )
}