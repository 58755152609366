import {Button, Col, Container, Row} from "reactstrap";
import React from "react";

export default function NotificationBanner(props: any) {

    const className = `rounded ${props.className}`

    const toggle = () => {
        props.toggleEmployeeTab()
    }

    return (
        <>
            <Container className={className}>
                <Col className={"px-1"}>
                    <Row>
                        <Col xs={1} className={"text-center"}>
                            <div className={"mt-0"}>
                                <i className="bi bi-exclamation-circle" style={{color: "white", fontSize: "34px"}}/>
                            </div>
                        </Col>
                        <Col xs={1}>
                            <div className="text-left mt-3 mb-0 text-sm">
                                <p className="text-nowrap font-weight-bold text-white">{props.alertTopic}</p>
                            </div>
                        </Col>
                        {props.resolution ? (
                            <>
                                <Col
                                    xs={{size: 7, offset: 1}}
                                    sm={{size: 7, offset: 1}}
                                    md={{size: 5, offset: 1}}
                                    lg={{size: 3, offset: 1}}
                                    xl={{size: 6, offset: 1}}
                                    className={""}>
                                    <div className="text-left mt-3 mb-0 text-sm px-3">
                                        <p className="text-nowrap text-white ">{props.alertMessage}</p>
                                    </div>
                                </Col>
                                <Col
                                    xl={3} className={"banner-btn-location"}>
                                    <Button onClick={toggle} className="banner-btn">
                                        <div className={"banner-btn-text"}>
                                            {"Click here to resolve >"}
                                        </div>
                                    </Button>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col xl={8} sm={{offset: 1}}>
                                    <div className="text-left mt-3 mb-0 text-sm px-3">
                                        <p className="text-nowrap text-white ">{props.alertMessage}</p>
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                </Col>
            </Container>
        </>
    )
}