import {Button, Col, Form, Input, Row} from "reactstrap";
import React, {useState} from "react";
import {formatTelUS, normalizeInput, parseTelUS} from "../../utils/phone-number.utils";

export default function EmployeeEditForm(props: any) {

    const [employeeName, setEmployeeName] = useState(props.employee.full_name)
    const [employeeEmail, setEmployeeEmail] = useState(props.employee.email)
    const [employeePhoneNumber, setEmployeePhoneNumber] = useState((props.employee.phone_number) ? formatTelUS(props.employee.phone_number) : '')

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        const phoneNumber = (employeePhoneNumber) ? parseTelUS(employeePhoneNumber) : null

        props.onSubmit(
            {
                full_name: employeeName,
                email: employeeEmail,
                phone_number: phoneNumber
            })
    }

    const handleChange = (value: any) => {
        const phoneNumber = normalizeInput(value, employeePhoneNumber)
        setEmployeePhoneNumber(phoneNumber);
    }

    const onCancel = () => {
        props.onCancel()
    }


    return (
        <>
            <Form onSubmit={onSubmit}>
                <Row className={"mt-1"} >
                    <Col
                        xs={2}
                        className={"text-left"}>
                        <Input
                            id="exampleName"
                            name="name"
                            required={true}
                            value={employeeName}
                            onChange={(e) => {
                                setEmployeeName(e.target.value)
                            }}
                            className={"employee-edit-input"}
                        />
                    </Col>
                    <div className={"employee-edit-column-spacer"}/>
                    <Col
                        xs={3}
                        className={"text-left"}>
                        <Input
                            id="exampleEmail"
                            name="email"
                            required={true}
                            value={employeeEmail}
                            onChange={(e) => {
                                setEmployeeEmail(e.target.value)
                            }}
                            className={"employee-edit-input"}
                        />
                    </Col>
                    <div className={"employee-edit-column-spacer"}/>
                    <Col
                        xs={2}
                        className={"text-left p-0"}>
                        <Input
                            id="examplePhoneNumber"
                            name="phoneNumber"
                            type={"tel"}
                            required={true}
                            value={employeePhoneNumber}
                            placeholder={"(xxx) xxx-xxxx"}
                            onChange={(e) => {
                                handleChange(e.target.value)
                            }}
                            className={"employee-edit-input"}
                        />
                    </Col>
                    <Col className={"text-end"}>
                        <Row>
                            <Col
                                className={"p-0"}>
                                <Button className={"employee-edit-save-btn"}>
                                    Save
                                </Button>
                            </Col>
                            <Col
                                xs={5}
                                className={"text-end p-0"}>
                                <Button onClick={onCancel} className={"employee-edit-cancel-btn"}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    )
}