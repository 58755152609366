import {List, Row} from "reactstrap";
import React from "react";
import Employee from "./employee.component";


export default function EmployeeListCard(props: any) {

    const onEmployeeUpdate = () => {
        props.onUpdate()
    }

    return (
        <>
            <Row className={"overflow-auto mx-1"} style={{maxHeight: "350px"}}>
                <List type="inline">
                    {props.employees.map((employee: any, index: any) => {
                        return (
                            <Row key={index} className={"payout-reports-accordion mt-2"}>
                                <Employee
                                    employee={employee}
                                    onEmployeeUpdate={onEmployeeUpdate}/>
                            </Row>
                        )
                    })}
                </List>
            </Row>
        </>
    )
}