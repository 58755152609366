import {Col, Row} from "reactstrap";
import React from "react";

export default function ScheduleComplete(props: any) {

    const infoText = "Thank you for choosing our service and scheduling a call with us!"
    const subInfoText = (
        <>
            During the call, we'll answer any questions you may have, guide you through
            the registration process, and ensure you have everything you need to get started
            with our service. <br /><br />
            To ensure that you don't miss your scheduled call, please add it to your calendar.
            If you have any questions before the call, don't hesitate to reach out to us at
            hello@getstabl.com. <br /><br />
            Thank you again for choosing our service. We're excited to speak with you soon
            and look forward to a great conversation!
        </>
    )

    return (
        <>
            <Col xs={4}/>
            <Col className={""}>
                <Row className={"mb-5 justify-content-center"}>
                    <Col className={"register-center-margin"}>
                        <Col className={""}>
                            <Row className={""}>
                                <Col className={"mt-4 justify-content-center registration-info-panel-title"}>
                                    {infoText}
                                </Col>
                            </Row>
                            <Row className={""} >
                                <div className={"mt-4 registration-info-panel-text"} style={{height: "fit-content"}}>
                                    {subInfoText}
                                </div>
                            </Row>
                        </Col>
                    </Col>
                    <Col xs={1} className={""}/>
                </Row>
            </Col>
            <Col xs={4}/>
        </>
    )
}