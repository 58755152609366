import { Button, Col, Form, Row } from "reactstrap";
import RegistrationInfoPanel from "../layout/registration-info-panel.component";
import React, { useEffect, useState } from "react";
import CompanyService from "../../services/company.service";

export default function RegistrationSuccess(props: any) {

    const [meetingBookSucceeded, setMeetingBookSucceeded] = useState(false)
    const [calendarLink, setCalendarLink] = useState<any>(null)

    useEffect(() => {
        async function fetchCompanyData() {
            try {
                const companyData = await CompanyService.getCompany();
                const firstName = companyData.data.contact_first_name ? companyData.data.contact_first_name : '';
                const lastName = companyData.data.contact_last_name ? companyData.data.contact_last_name : '';
                const email = companyData.data.email ? companyData.data.email : '';
                const contactPhone = companyData.data.contact_phone ? companyData.data.contact_phone : '';
                const companyName = companyData.data.company_name ? companyData.data.company_name : '';
                setCalendarLink(process.env.REACT_APP_HUBSPOT_CALENDAR_URL +
                    "?firstname=" + encodeURIComponent(firstName) +
                    "&lastname=" + encodeURIComponent(lastName) +
                    "&email=" + encodeURIComponent(email) +
                    "&mobilephone=" + encodeURIComponent(contactPhone)+
                    "&company=" + encodeURIComponent(companyName));
            } catch (error) {
                console.log(error);
            }
        }

        fetchCompanyData();
    }, []);


    useEffect(() => {
        window.addEventListener("message", function (e) {
            if (e.origin !== process.env.REACT_APP_HUBSPOT_CALENDAR_ORIGIN_URL) return
            setMeetingBookSucceeded(e.data.meetingBookSucceeded)
        });
    }, []);

    const panelSubInfoText = <div>
        Congratulations on successfully connecting Stabl Pay with your payroll system!
        In order to ensure a seamless experience, we provide Clover Merchants a complimentary
        15-minute call with our customer success team. During the call, we'll cover important
        topics such as agreements, deductions, payroll cycles, employee contact information,
        and tips and tricks to improve recruiting and retention. In addition, we'll provide you
        with an overview, answer any questions you may have, and share some helpful collateral.
        <br/><br/>
        Don't miss out on this opportunity to maximize the benefits of Stabl Pay for your business.
        Schedule a convenient time now by using the calendar to the right.

    </div>

    const onSubmit = () => {
        props.onSubmit()
    }

    return (
        <>
            <Col className={""}>
                <Row className={"mb-5"}>
                    <Col xs={4} className={"register-center-margin"}>
                        <RegistrationInfoPanel
                            infoText={"Congrats! Book Your Complimentary Call To Launch!"}
                            subInfoText={panelSubInfoText}
                            smallTitleText={true}/>
                    </Col>
                    <Col xs={1} className={""}/>
                    <Col xs={7} className={"registration-form-container my-0"}>
                        <div className={"my-4 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-2"}>
                            <Row>
                                <iframe title={"hubspot-calendar"}
                                        id={"calendar-iframe"}
                                        src={calendarLink}
                                        style={{
                                            width: '100vw',
                                            height: '60vh',
                                            position: 'relative'
                                        }}/>
                            </Row>
                        </div>
                        <Row className={"my-4 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-2"}>
                            <Form className={"mb-0"} onSubmit={onSubmit}>
                                <Row style={{justifyContent: "end"}}>
                                    <div style={{width: "200px"}} className={"p-0"}>
                                        <Row className={"m-0"} style={{justifyContent: "center"}}>
                                            <div style={{height: "38px", width: "10px"}} className={"mx-1"}>
                                            </div>
                                            <div style={{height: "38px", width: "160px"}} className={""}>
                                                <Button className={"go-to-dashboard-button"}
                                                        disabled={!meetingBookSucceeded}>
                                                    Go to Dashboard
                                                </Button>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>
                            </Form>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </>
    )
}