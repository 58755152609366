import {adapterApiClient} from "./axios-helper"
import {SelfServeState} from "../enums/self-serve-state.enum";

class CompanyService {

    async getCompany() {
        return await adapterApiClient.get('/clover/company')
    }

    async updateCompany(company: any) {
        return await adapterApiClient.put('/clover/company', company)
    }

    async updateCompanyPayCycle(payCycle: any) {
        return await adapterApiClient.put('/clover/company/pay-cycle', payCycle)
    }

    async getRegistrationStatus() {
        return await adapterApiClient.get('/clover/company/registration-status')
    }

    async updateCloverRegistration(registrationStatus: any) {

        let registration = {
            clover_registration:
                {
                    registration_status: registrationStatus
                }
        }
        return await adapterApiClient.put('/clover/company/clover-registration', registration)
    }

    async createCompanyEmployee(employee: any) {
        return await adapterApiClient.post('/clover/company/employee-row', employee)
    }

    async getCompanyEmployees() {
        return await adapterApiClient.get('/clover/company/employees')
    }

    async updateCompanyEmployee(id: any, employee: any) {
        return await adapterApiClient.put(`/clover/company/employees/${id}`, employee)
    }

    async initializeCompanyEmployeeList() {
        return await adapterApiClient.get('/clover/company/initialize-employee-data')
    }

    async getCompanyAdvances() {
        return await adapterApiClient.get('/clover/company/advances')
    }

    async waitListCompany(status: string) {
        return await adapterApiClient.get(`/clover/company/wait-list?status=${status}`)
    }

    async checkSelfServe(headcount: number, locationCount: number) {

        const headcountStatus = await this.checkHeadcount(headcount)
        const locationCountStatus = await this.checkLocationCount(locationCount)

        if (headcountStatus === SelfServeState.WAIT_LIST || locationCountStatus === SelfServeState.WAIT_LIST) {
            return SelfServeState.WAIT_LIST
        } else if (headcountStatus === SelfServeState.SELF_SERVE && locationCountStatus === SelfServeState.SELF_SERVE) {
            return SelfServeState.SELF_SERVE
        } else {
            return SelfServeState.SALES_OPTION
        }
    }

    private async checkHeadcount(headcount: number) {

        let headcountLow: number = (process.env.REACT_APP_HEADCOUNT_THRESHOLD_LOW) ? +process.env.REACT_APP_HEADCOUNT_THRESHOLD_LOW : 1
        let headcountHigh: number = (process.env.REACT_APP_HEADCOUNT_THRESHOLD_HIGH) ? +process.env.REACT_APP_HEADCOUNT_THRESHOLD_HIGH : 10

        if (headcount < headcountLow) {
            return SelfServeState.WAIT_LIST
        } else if (headcount >= headcountLow && headcount <= headcountHigh) {
            return SelfServeState.SELF_SERVE
        } else if (headcount > headcountHigh) {
            return SelfServeState.SALES_OPTION
        }
    }

    private async checkLocationCount(locationCount: number) {

        let locationCountLow: number = (process.env.REACT_APP_LOCATION_THRESHOLD_LOW) ? +process.env.REACT_APP_LOCATION_THRESHOLD_LOW : 1
        let locationCountHigh: number = (process.env.REACT_APP_LOCATION_THRESHOLD_HIGH) ? +process.env.REACT_APP_LOCATION_THRESHOLD_HIGH : 10

        if (locationCount < locationCountLow) {
            return SelfServeState.WAIT_LIST
        } else if (locationCount >= locationCountLow && locationCount <= locationCountHigh) {
            return SelfServeState.SELF_SERVE
        } else if (locationCount > locationCountHigh) {
            return SelfServeState.SALES_OPTION
        }

    }

}

export default new CompanyService()