import axios from 'axios'
import {onError} from "./axios-error-helper"

export const backendApiClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API,
    timeout: parseInt(process.env.REACT_APP_CONFIG_TIMEOUT as string)
})

export const adapterApiClient = axios.create({
    baseURL: process.env.REACT_APP_ADAPTER_API,
    timeout: parseInt(process.env.REACT_APP_CONFIG_TIMEOUT as string)
})

const authInterceptor = (config: any) => {
    const authToken = localStorage.getItem('auth_token')
    if (!authToken) return config
    return {
        ...config,
        headers: {
            ...config.headers,
            authorization: `bearer ${authToken}`
        }
    }
}

adapterApiClient.interceptors.request.use(authInterceptor)
adapterApiClient.interceptors.response.use(undefined, onError)

