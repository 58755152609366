import {backendApiClient} from "./axios-helper";

class PayrollProviderService {

    async getPayrollProviders() {
        const response = await backendApiClient.get('/payroll_providers')
        return response.data
    }

}

export default new PayrollProviderService()