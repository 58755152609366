import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import React from "react";
import Select from 'react-select';
import RegistrationInfoPanel from "../layout/registration-info-panel.component";
import {normalizeInput, parseTelUS} from "../../utils/phone-number.utils";
import {useHistoryState} from "../../hooks/use-history-state.hook";

export default function InformationForm(props: any) {

    const [providerId, setProviderId] = useHistoryState('providerId', '')
    const [startDate, setStartDate] = useHistoryState('startDate', '')
    const [endDate, setEndDate] = useHistoryState('endDate', '')
    const [payDate, setPayDate] = useHistoryState('payDate', '')
    const [headcount, setHeadcount] = useHistoryState('headcount', '')
    const [locationCount, setLocationCount] = useHistoryState('locationCount', '')
    const [contactPhoneNumber, setContactPhoneNumber] = useHistoryState('contactPhoneNumber', '')
    const [companyPhoneNumber, setCompanyPhoneNumber] = useHistoryState('companyPhoneNumber', '')

    const [selectedOption, setSelectedOption] = useHistoryState('selectedOption', null);

    const handleChangeOption = (selectedOption: any) => {
        setSelectedOption(selectedOption);
        setProviderId(selectedOption.value);
    };

    const options = [
        ...props.providers.map((provider: any) => ({ value: provider.id, label: provider.name })),
        { value: -1, label: 'Other' },
    ];


    const handleChange = (value: any) => {
        const phoneNumber = normalizeInput(value, contactPhoneNumber)
        setContactPhoneNumber(phoneNumber);
    }

    const handleCompanyNumberChange = (value: any) => {
        const phoneNumber = normalizeInput(value, companyPhoneNumber)
        setCompanyPhoneNumber(phoneNumber);
    }

    const onSubmit = (e: React.SyntheticEvent) => {
        let provider = props.providers.find((provider: any) => {
            return provider.id === providerId
        })

        let pinwheelCompatible = (provider) ? provider.pinwheel_compatible : false

        e.preventDefault()
        props.onSubmit(
            {
                providerId: providerId,
                pinwheelCompatible: pinwheelCompatible,
                periodStartDate: startDate,
                periodEndDate: endDate,
                payDate: payDate,
                headcount: headcount,
                locationCount: locationCount,
                contactNumber: parseTelUS(contactPhoneNumber),
                companyNumber: parseTelUS(companyPhoneNumber)
            })
    }

    return (
        <Col className={""}>
            <Row className={"mb-5"}>
                <Col xs={4} className={"register-center-margin"}>
                    <RegistrationInfoPanel
                        infoText={"Tell Us About Your Company & Systems"}
                        subInfoText={"Let’s start with some basic information about your company. For " +
                        "the “Payroll Information” section, please use real dates so we can mirror your " +
                        "pay cycle correctly and validate additional data that will be collected in the coming " +
                        "steps."}/>
                </Col>
                <Col xs={1} className={""}/>
                <Col xs={7} className={"registration-form-container"}>
                    <Form className={"my-4 mx-sm-1 mx-md-1 mx-lg-2 mx-xl-3"} onSubmit={onSubmit}>
                        <Row className={"mb-3"}>
                            <Col>
                                <Row>
                                    <div className={"text-left form-section-label"}>
                                        Company Information
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <div className={"text-left form-label-text"}>
                                        <Label for="headcount">
                                            Number of Employees
                                        </Label>
                                    </div>
                                    <Input
                                        id="headcount"
                                        name="headcount"
                                        type="number"
                                        min={0}
                                        required={true}
                                        value={headcount}
                                        onChange={(e) => {
                                            setHeadcount(e.target.value)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <div className={"text-left form-label-text"}>
                                        <Label for="numberLocations">
                                            Number of Locations
                                        </Label>
                                    </div>
                                    <Input
                                        id="numberLocations"
                                        name="numberLocations"
                                        type="number"
                                        min={0}
                                        required={true}
                                        value={locationCount}
                                        onChange={(e) => {
                                            setLocationCount(e.target.value)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col xs={6}>
                                <FormGroup>
                                    <div className={"text-left form-label-text"}>
                                        <Label for="examplePhoneNumber">
                                            Company Phone Number
                                        </Label>
                                    </div>
                                    <Input
                                        id="companyPhoneNumber"
                                        name="phoneNumber"
                                        type={"tel"}
                                        required={true}
                                        value={companyPhoneNumber}
                                        placeholder={"(xxx) xxx-xxxx"}
                                        onChange={(e) => {
                                            handleCompanyNumberChange(e.target.value)
                                        }}

                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <div className={"text-left form-label-text"}>
                                        <Label for="examplePhoneNumber">
                                            Your Phone Number
                                        </Label>
                                    </div>
                                    <Input
                                        id="contactPhoneNumber"
                                        name="phoneNumber"
                                        type={"tel"}
                                        required={true}
                                        value={contactPhoneNumber}
                                        placeholder={"(xxx) xxx-xxxx"}
                                        onChange={(e) => {
                                            handleChange(e.target.value)
                                        }}

                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className={"mb-4"}>
                            <Col>
                                <Row>
                                    <div className={"text-left form-section-label"}>
                                        Payroll Information (for current pay cycle)
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <div className={"text-left form-label-text"}>
                                        <Label for="payrollSelect">
                                            Your Payroll Provider
                                        </Label>
                                    </div>
                                    <Select
                                        id="payrollSelect"
                                        name="select"
                                        value={selectedOption}
                                        onChange={handleChangeOption}
                                        options={options}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <div className={"text-left form-label-text"}>
                                        <Label for="payPeriodStart">
                                            Pay Period Start Date
                                        </Label>
                                    </div>
                                    <Input
                                        id="payPeriodStart"
                                        name="date"
                                        placeholder="date placeholder"
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => {
                                            setStartDate(e.target.value)
                                        }}
                                        required={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className={"mb-2"}>
                            <Col xs={6}>
                                <FormGroup>
                                    <div className={"text-left form-label-text"}>
                                        <Label for="payPeriodEnd">
                                            Pay Period End Date
                                        </Label>
                                    </div>
                                    <Input
                                        id="payPeriodEnd"
                                        name="date"
                                        placeholder="date placeholder"
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => {
                                            setEndDate(e.target.value)
                                        }}
                                        required={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <div className={"text-left form-label-text"}>
                                        <Label for="payDate">
                                            Payday for Employees
                                        </Label>
                                    </div>
                                    <Input
                                        id="payDate"
                                        name="date"
                                        placeholder="date placeholder"
                                        type="date"
                                        value={payDate}
                                        onChange={(e) => {
                                            setPayDate(e.target.value)
                                        }}
                                        required={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className={"my-0"}>
                            <Col xs={6}/>
                            <Col xs={6}>
                                <Row className={"m-0"} style={{justifyContent: "end"}}>
                                    <Button className={"registration-save-continue-button"}
                                            style={{height: "38px", width: "100px"}}>
                                        Continue
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>
        </Col>
    )
}