import {Button, Col, Form, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import RegistrationInfoPanel from "../layout/registration-info-panel.component";

export default function ScheduleCall(props: any) {
    const calendarLink = process.env.REACT_APP_HUBSPOT_CALENDAR_URL
    const [meetingBookSucceeded, setMeetingBookSucceeded] = useState(false)

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        props.onSubmit({success: meetingBookSucceeded})
    }

    useEffect(() => {
        window.addEventListener("message", function (e) {
            if (e.origin !== process.env.REACT_APP_HUBSPOT_CALENDAR_ORIGIN_URL) return
            setMeetingBookSucceeded(e.data.meetingBookSucceeded)
        });
    }, []);

    return (
        <>
            <Col xs={4} className={"register-center-margin"}>
                <RegistrationInfoPanel
                    infoText={"Please Book a time"}
                    subInfoText={"Let's set up a time for a call!"}/>
            </Col>
            <Col xs={1} className={""}/>
            <Col xs={7} className={"registration-form-container"}>
                <div className={"my-4 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-2"}>
                    <Row>
                        <iframe title={"hubspot-calendar"}
                                id={"calendar-iframe"}
                                src={calendarLink}
                                style={{
                                    width: '100vw',
                                    height: '60vh',
                                    position: 'relative'
                                }}/>
                    </Row>

                </div>
                <Row className={"my-0 mx-sm-1 mx-md-1 mx-lg-2 mx-xl-3"}>
                    <Form className={"mb-4"} onSubmit={onSubmit}>
                        <Row style={{justifyContent: "end"}}>
                            <div style={{width: "200px"}} className={"p-0"}>
                                <Row className={"m-0"} style={{justifyContent: "center"}}>
                                    <div style={{height: "38px", width: "80px"}} className={"mx-1"}>
                                        <Button className={"back-button"} onClick={props.onGoBack}>
                                            Back
                                        </Button>
                                    </div>
                                    <div style={{height: "38px", width: "100px"}} className={""}>
                                        <Button className={"registration-save-continue-button"}
                                                disabled={!meetingBookSucceeded}>
                                            Continue
                                        </Button>
                                    </div>
                                </Row>
                            </div>
                        </Row>
                    </Form>
                </Row>
            </Col>
        </>
    )
}