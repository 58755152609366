export const onError = (error: any) => {
    if (!error || !error.response) {
        // something else is issue here - route it
        return Promise.reject(error)
    }

    switch (error.response.status) {
        case 500:
        case 501:
        case 502:
        case 503:
            return Promise.reject(error.response)
        case 401:
            localStorage.removeItem('auth_token')
            window.location.href = `${process.env.REACT_APP_CLOVER_ENVIRONMENT_URL}/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}`
            break;
        case 403:
            localStorage.removeItem('auth_token')
            window.location.href = `${process.env.REACT_APP_CLOVER_ENVIRONMENT_URL}/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}`
            break;
        case 404:
        default: {
            return Promise.reject(error.response);
        }
    }
    return Promise.reject(error);
};
