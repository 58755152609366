import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './assets/stylesheets/App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./components/dashboard-components/dashboard.component";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


import CompanyRegister from "./components/registration-components/company-register.component";
import { Container } from "reactstrap";
import { ToastContainer } from "react-toastify";



function App() {
    return (
        <Container fluid className="base">
            <Router>
                <Routes>
                    <Route path="/" element={<Dashboard/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="/registration" element={<CompanyRegister/>}/>
                </Routes>
            </Router>
            <ToastContainer/>
        </Container>
    );
}


export default App;
