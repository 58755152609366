import {Col, Row} from "reactstrap";
import React from "react";
import BackButton from "./back-button.component";

export default function RegistrationInfoPanel(props: any) {

    const onGoBack = () => {
        props.onGoBack()
    }

    const infoPanelTextClass = props.smallTitleText ? "registration-info-panel-alt-small" : "text-left registration-info-panel-title";

    return (
        <>
            {props.onGoBack !== null && (
                <>
                    <Row>
                        <BackButton onGoBack={onGoBack} active={props.activeBackButton}/>
                    </Row>
                </>
            )}
            <Col className={""}>
                <Row>
                    <div className={infoPanelTextClass}>
                        {props.infoText}
                    </div>
                </Row>
                <Row className={""} >
                    <div className={"mt-4 text-left registration-info-panel-text"} style={{height: "fit-content"}}>
                        {props.subInfoText}
                    </div>
                </Row>
            </Col>
        </>
    )
}