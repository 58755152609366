/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'

import CompanyService from "../../services/company.service"
import { useNavigate, useSearchParams } from "react-router-dom";
import { isCurrentPayPeriod } from "../../utils/date.utils";

import { Col, Container, Row } from "reactstrap";
import { RegistrationStatus } from "../../enums/registration-status.enum";
import Loading from "../layout/loading.component";
import DashboardNavigationHeader from "./dashboard-navigation-header.component";
import DashboardTab from "./dashboard-tab.component";
import EmployeeTab from "./employee-tab.component";
import Footer from "../layout/footer.component";

export default function Dashboard(props: any) {

    const [searchParams] = useSearchParams();
    const authToken = searchParams.get('auth_token')

    if (authToken) {
        localStorage.setItem('auth_token', searchParams.get('auth_token') as string)
    }
    const [company, setCompany] = useState<any>(null)
    const [companyUtcOffset, setCompanyUtcOffset] = useState<any>(0)
    const [advancePeriods, setAdvancePeriods] = useState<any>(null)
    const [currentTotal, setCurrentTotal] = useState<any>('$0')
    const [currentCount, setCurrentCount] = useState<any>(0)
    const [currentPeople, setCurrentPeople] = useState<any>(0)
    const [isBusy, setBusy] = useState(true)
    const navigate = useNavigate()
    const [toggleDashboard, setToggleDashboard] = useState(true)
    const [employees, setEmployees] = useState<any>([])
    const [missingEmployeeData, setMissingEmployeeData] = useState(false)
    const [signedAccessAgreements, setSignedAccessAgreements] = useState(false)

    const toggle = (value: number) => {
        if (value === 1) {
            setToggleDashboard(true)
        } else if (value === 2) {
            setToggleDashboard(false)
        }
    }

    useEffect(() => {
        async function getCompany() {
            try {
                const response = await CompanyService.getCompany()
                const company = response.data
                setCompany(company)
                setCompanyUtcOffset(company.utc_offset)
                setSignedAccessAgreements(company.accepted_agreements)
                if (company.clover_registration.registration_status !== RegistrationStatus.COMPLETE) {
                    navigate('/registration')
                }
                setBusy(false)

            } catch (e) {
                // OR route to login here...
                window.location.href = `${process.env.REACT_APP_CLOVER_ENVIRONMENT_URL}/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}`
            }
        }

        getCompany()
    }, [])

    useEffect(() => {
        async function getAdvances() {
            let advances = await CompanyService.getCompanyAdvances()
            setAdvancePeriods(advances.data)
        }

        getAdvances()
    }, [])

    useEffect(() => {
        async function getCurrentPayPeriodValues() {
            if (advancePeriods && company && advancePeriods[0].payPeriod !== null && isCurrentPayPeriod(advancePeriods[0].payPeriod,
                company.utc_offset)) {
                setCurrentTotal(`$${advancePeriods[0].advancesPaidTotal}`)
                setCurrentCount(advancePeriods[0].advanceCount)
                setCurrentPeople(advancePeriods[0].employeeCount)
            }
        }

        getCurrentPayPeriodValues()
    }, [advancePeriods])

    useEffect(() => {
        async function getEmployeeList() {
            await CompanyService.initializeCompanyEmployeeList()
            const employeesResult = await CompanyService.getCompanyEmployees()
            setEmployees(employeesResult.data.employee_list)
            setMissingEmployeeData(employeesResult.data.missing_data)
        }

        getEmployeeList()
    }, [])

    const updateEmployeeList = async () => {
        const employeesResult = await CompanyService.getCompanyEmployees()
        setEmployees(employeesResult.data.employee_list)
        setMissingEmployeeData(employeesResult.data.missing_data)
    }

    return (
        <>
            <Row>
                {isBusy ? (
                    <>
                        <Row className={"mt-5 mb-3"}>
                            <div className="mt-5 mb-5">
                                <Loading/>
                            </div>
                        </Row>
                    </>
                ) : (
                    <>
                        <Col className={"mx-sm-2 mx-md-3 mx-lg-4 mx-xl-5"}>
                            <Container fluid className={"my-4"} style={{borderBottom: "1px solid #3F85FC"}}>
                                <div>
                                    <DashboardNavigationHeader
                                        companyName={company ? company.company_name : ''}
                                        toggle={toggle}
                                        dashboard={toggleDashboard}/>
                                </div>
                            </Container>

                            {toggleDashboard ? (
                                <DashboardTab
                                    currentTotal={currentTotal}
                                    currentCount={currentCount}
                                    currentPeople={currentPeople}
                                    advancePeriods={advancePeriods}
                                    companyUtcOffset={companyUtcOffset}
                                    missingData={missingEmployeeData}
                                    signedAccessAgreements={signedAccessAgreements}
                                    toggle={toggle}/>
                            ) : (
                                <EmployeeTab
                                    employees={employees}
                                    onUpdate={updateEmployeeList}
                                    missingData={missingEmployeeData}
                                    signedAccessAgreements={signedAccessAgreements}/>
                            )}
                        </Col>
                    </>

                )}
            </Row>
            <Row >
                <Col className={"p-0 mx-sm-2 mx-md-3 mx-lg-4 mx-xl-5"}>
                    <Footer/>
                </Col>
            </Row>
        </>
    )
}