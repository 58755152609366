import {Col, Row} from "reactstrap";
import React from "react";

export default function PayoutReportHeaders(props: any) {
    return (
        <>
            <Row className={"payout-reports-accordion-body-label-text"}>
                <Col>
                    <Row>
                        <Col
                            xs={2}
                            className={"text-left"}>
                            <div>
                                Employee
                            </div>
                        </Col>
                        <Col
                            xs={3}
                            className={"text-left"}>
                            <div>
                                Date
                            </div>
                        </Col>
                        <Col
                            className={"text-right"}>
                            <div>
                                Original
                            </div>
                        </Col>
                        <Col
                            className={"text-right"}>
                            <div>
                                Fee
                            </div>
                        </Col>
                        <Col
                            className={"text-right"}>
                            <div>
                                Total
                            </div>
                        </Col>
                    </Row>
                    <Row className={"mt-1 border-bottom"}>
                    </Row>
                </Col>
            </Row>
        </>
    )
}