import React from 'react'
import {Col, Row} from "reactstrap";

export default function WaitList(props: any) {

    const infoText = "Thank you for your interest in our service!"
    const subInfoText = (
        <>
            Thank you for your interest in our service! Your company has been added to our waitlist.<br /><br />
            If you would like to receive priority access, you can refer other companies to our service. The more
            referrals you provide, the sooner you will gain access. Please feel free to make warm introductions to
            hello@getstabl.com.<br /><br />
            We appreciate your support and look forward to serving you soon!
        </>
    )

    return (
        <>
            <Col xs={4}/>
            <Col className={""}>
                <Row className={"mb-5 justify-content-center"}>
                    <Col className={"register-center-margin"}>
                        <Col className={""}>
                            <Row className={""}>
                                <Col className={"mt-4 justify-content-center registration-info-panel-title"}>
                                    {infoText}
                                </Col>
                            </Row>
                            <Row className={""} >
                                <div className={"mt-4 registration-info-panel-text"} style={{height: "fit-content"}}>
                                    {subInfoText}
                                </div>
                            </Row>
                        </Col>
                    </Col>
                    <Col xs={1} className={""}/>
                </Row>
            </Col>
            <Col xs={4}/>
        </>
    )

}
