import React from "react";
import {List, Row} from "reactstrap";
import Advance from "./advance.component";
import PayoutReportHeaders from "./payout-report-headers.component";

export default function AdvanceList(props: any) {

    return (
        <List type="inline">
            <Row>
                <PayoutReportHeaders/>
            </Row>
            {props.advances.map((advance: any, index: any) => {
                return (
                    <Row key={index}>
                        <Advance advance={advance}/>
                    </Row>

                )
            })}
        </List>
    )
}