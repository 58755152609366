/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Col, Form, Row} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import PinwheelService from "../../services/pinwheel.service";
import PinwheelModal from "@pinwheel/react-modal";
import RegistrationInfoPanel from "../layout/registration-info-panel.component";

export default function SystemsCheckForm(props: any) {

    const [linkToken, setLinkToken] = useState('')
    const [open, setOpen] = useState(false)

    const [waitList, setWaitList] = useState(false)
    const [success, setSuccess] = useState(false)
    const [retryConnection, setRetryConnection] = useState(false)

    const [messageHeaderText, setMessageHeaderText] = useState("We’re Already Integrated. Let’s Confirm the Connection.")
    const [messageBodyText, setMessageBodyText] = useState(<><p>We work with over 200 HR systems.
         In this next step, we will connect to your provider and ensure that data
        is flowing properly.</p>
        </>)

    const [backgroundIcon, setBackgroundIcon] = useState("./assets/images/systems-check-icon.svg")

    let retry = useRef(true)

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        props.onSubmit({waitList: waitList})
    }

    const getLinkToken = async () => {
        try {
            const token = await PinwheelService.getPinwheelLink("payroll")
            setLinkToken(token)
            setOpen(true)
            return undefined
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }

    const [buttonFunction, setButtonFunction] = useState(
        <Button onClick={getLinkToken} className={"registration-save-continue-button"}>Continue</Button>)

    useEffect(() => {
        async function setSuccessText() {
            if (success) {
                setMessageHeaderText("Congrats! Your Connection Looks Good!")
                setBackgroundIcon("./assets/images/systems-check-success-icon.svg")
                setMessageBodyText(<><p>Click continue to move forward.</p></>)
                setButtonFunction(
                    <Button className={"registration-save-continue-button"}>Continue</Button>)
            }
        }

        setSuccessText()
    }, [success])

    useEffect(() => {
        async function setWaitListText() {
            if (waitList) {
                setMessageHeaderText("Thank You")
                setBackgroundIcon("./assets/images/systems-check-success-icon.svg")
                setMessageBodyText(<><p>Please click continue to move forward.</p></>)
                setButtonFunction(
                    <Button onClick={onSubmit}
                            className={"registration-save-continue-button"}>Continue</Button>)
            }
        }

        setWaitListText()
    }, [waitList])

    useEffect(() => {
        async function setRetryConnectionText() {
            if (retryConnection) {
                setMessageHeaderText("There was a problem retrieving your shift data.")
                setBackgroundIcon("./assets/images/systems-check-error-retry-icon.svg")
                setMessageBodyText(<><p>Please have an hourly employee login so that we can verify that we are able to
                    retrieve hours worked.</p></>)
                setButtonFunction(<Button onClick={getLinkToken}
                                          className={"registration-save-continue-button"}>Retry</Button>)
            }
        }

        setRetryConnectionText()
    }, [retryConnection])

    const validatePinwheelData = async (accountToken: string) => {

        const pinwheelDataRequest = {
            accountToken: accountToken,
            payPeriod: props.payPeriod
        }
        const dataValidated = await PinwheelService.validatePinwheelData(pinwheelDataRequest)

        if (dataValidated.data.incomeDataPresent && dataValidated.data.employmentDataPresent) {
            if (dataValidated.data.shiftDataPresent) {
                return props.onSubmit({waitList: waitList})
            } else if (retry.current) {
                retry.current = false
                setRetryConnection(true)
            } else {
                setRetryConnection(false)
                setWaitList(true)
            }

        } else {
            setWaitList(true)
        }

    }


    const onSuccess = async (result: any) => {
        try {
            await validatePinwheelData(result.accountId)
            setOpen(false)
            return undefined
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }

    const onLogin = async (result: any) => {
        console.log('Successful login')
    }

    const onError = async (result: any) => {
    }

    const handleExit = async () => {
        setOpen(false)
    }

    return (
        <>
            <Col className={""}>
                <Row className={"mb-5"}>
                    <Col xs={4} className={"register-center-margin"}>
                        <RegistrationInfoPanel
                            infoText={"Systems Check"}
                            subInfoText={<>In this step, you will
                                log in to your payroll system through a secure portal. This authorizes us to receive employment status, wage and hours for the user account you log in
                                with. If possible, please log in with an account that has hours logged in the time & attendance system. This will help
                                verify that we are seeing hours properly, in addition to verifying the employment status and income checks.
                                <br/><br/>
                                This is similar to what each employee will do the first time they register with us later. The test today verifies the data is accessible
                                and complete for purposes of approving advances against already earned pay.
                                </>}/>
                    </Col>
                    <Col xs={1} className={""}/>
                    <Col xs={7} className={"registration-form-container"}>
                        <div className={"my-4 mx-sm-1 mx-md-1 mx-lg-2 mx-xl-3"}>
                            <Row className={"mb-1 mb-md-3 mb-lg-5"}>
                                <div className={"systems-check-header"}>
                                    {messageHeaderText}
                                </div>
                            </Row>
                            <Row className={"mb-1 mb-md-3 mb-lg-5 d-flex align-items-center justify-content-center"}>
                                <img src={backgroundIcon} alt={"systems-check"}
                                     className={"systems-check-icon"}/>
                            </Row>
                            <Row>
                                <div className={"mb-1 mb-md-2 mb-lg-3 systems-check-body"}>
                                    {messageBodyText}
                                </div>
                            </Row>
                        </div>
                        <Row className={"my-0 mx-sm-1 mx-md-1 mx-lg-2 mx-xl-3"}>
                            <Form className={"mb-xl-4 mb-sm-1 mb-md-2 mb-lg-3"} onSubmit={onSubmit}>
                                <Row style={{justifyContent: "end"}}>
                                    <div style={{width: "200px"}} className={"p-0"}>
                                        <Row className={"m-0"} style={{justifyContent: "center"}}>
                                            <div style={{height: "38px", width: "80px"}} className={"mx-1"}>
                                                <Button className={"back-button"} onClick={props.onGoBack}>
                                                    Back
                                                </Button>
                                            </div>
                                            <div style={{height: "38px", width: "100px"}} className={""}>
                                                {buttonFunction}
                                            </div>
                                        </Row>
                                    </div>
                                </Row>
                            </Form>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <PinwheelModal
                linkToken={linkToken}
                open={open}
                onSuccess={onSuccess}
                onLogin={onLogin}
                onExit={handleExit}
                onError={onError}
            />
        </>
    )
}
