/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import InformationForm from "./information-form.component";
import SystemsCheckForm from "./systems-check-form.component";
import CommunicationsConfirmation from "./communications-confirmation.component";
import {useNavigate, useSearchParams} from "react-router-dom";
import CompanyService from "../../services/company.service";
import PayrollProviderService from "../../services/payroll-provider.service"
import TimeAndAttendanceProviderService from "../../services/time-and-attendance-provider.service";
import SystemsCheckFormPinwheel from "./systems-check-form-pinwheel.component";
import {RegistrationFormState} from "../../enums/registration-form-state.enum";
import {RegistrationStatus} from "../../enums/registration-status.enum";
import SystemsTimeAndAttendance from "./systems-time-and-attendance.component";
import WaitList from "../wait-list-components/wait-list.component";
import Loading from "../layout/loading.component";
import {SelfServeState} from "../../enums/self-serve-state.enum";
import ScheduleCall from "./schedule-call.component";
import ScheduleComplete from "./schedule-complete.component";
import Footer from "../layout/footer.component";
import RegistrationSuccess from "./registration-success.component";


export default function CompanyRegister() {

    const [formState, setFormState] = useState('')
    const [payrollProviders, setPayrollProviders] = useState<any>([])
    const [timeAndAttendanceProviders, setTimeAndAttendanceProviders] = useState<any>([])
    const navigate = useNavigate()
    const [isBusy, setIsBusy] = useState(false)
    const [payPeriod, setPayPeriod] = useState({})
    const [progressValue, setProgressValue] = useState(1)
    const [indicatorBar, setIndicatorBar] = useState("./assets/images/info-stage-indicator-bar.png")

    const logo = "./assets/images/Logo-Blue.png"

    const [searchParams] = useSearchParams();
    const authToken = searchParams.get('auth_token')
    if (authToken) {
        localStorage.setItem('auth_token', searchParams.get('auth_token') as string)
    }

    useEffect(() => {
        async function getPayrollProviders() {
            if (formState === RegistrationFormState.INFORMATION_PAGE) {
                let providers = await PayrollProviderService.getPayrollProviders()
                setPayrollProviders(providers)
            }
        }

        getPayrollProviders()
    }, [formState])

    useEffect(() => {
        async function setStateIcons() {
            if (progressValue === 1) {
                //Reset Values
                setIndicatorBar("./assets/images/info-stage-indicator-bar.png")
            } else if (progressValue === 2) {
                setIndicatorBar("./assets/images/systems-check-indicator-bar.png")
            } else if (progressValue === 3) {
                setIndicatorBar("./assets/images/communications-indicator-bar.png")
            }
        }

        setStateIcons()
    }, [progressValue])

    useEffect(() => {
        async function getRegistrationStatus() {
            let status = await CompanyService.getRegistrationStatus()
            if (status.data.registration_status === RegistrationStatus.WAIT_LIST) {
                setFormState(RegistrationFormState.WAIT_LIST_PAGE)
                setProgressValue(3)
                return
            } else if (status.data.registration_status === RegistrationStatus.INFORMATION_STATE) {
                setFormState(RegistrationFormState.INFORMATION_PAGE)
                setProgressValue(1)
                return
            } else if (status.data.registration_status === RegistrationStatus.SYSTEMS_CHECK_STATE) {
                setFormState(RegistrationFormState.SYSTEMS_CHECK_PAGE_ONE)
                setProgressValue(2)
                return
            } else if (status.data.registration_status === RegistrationStatus.SELF_SERVE_OPTION_STATE) {
                setFormState(RegistrationFormState.SYSTEMS_CHECK_PAGE_ONE)
                setProgressValue(1)
                return
            } else if (status.data.registration_status === RegistrationStatus.CALL_LIST) {
                setFormState(RegistrationFormState.SCHEDULED_COMPLETE_PAGE)
                setProgressValue(3)
                return
            } else if (status.data.registration_status === RegistrationStatus.COMMUNICATIONS_CONFIRMATION_STATE) {
                setFormState(RegistrationFormState.CONFIRMATION_PAGE)
                setProgressValue(3)
                return
            } else if (status.data.registration_status === RegistrationStatus.SUCCESS_STATE) {
                setFormState(RegistrationFormState.SUCCESS_PAGE)
                setProgressValue(3)
            } else {
                navigate('/dashboard')
            }
        }

        getRegistrationStatus()
    }, [])

    useEffect(() => {
        async function getTimeAndAttendanceProviders() {
            if (formState === RegistrationFormState.SYSTEMS_TIME_AND_ATTENDANCE) {
                let providers = await TimeAndAttendanceProviderService.getTimeAndAttendanceProviders()
                setTimeAndAttendanceProviders(providers)
            }
        }

        getTimeAndAttendanceProviders()
    }, [formState])

    const handleTAndAProviderData = async (data: any) => {

        const company = {
            company: {time_and_attendance_provider_id: data.providerId}
        }

        try {
            await CompanyService.updateCompany(company)
            setFormState(RegistrationFormState.CONFIRMATION_PAGE)
            setProgressValue(3)
        } catch (e) {
            console.error(e)
        }
    }

    async function waitListCompany() {
        setIsBusy(true)
        await CompanyService.waitListCompany(RegistrationStatus.WAIT_LIST)
        setIsBusy(false)
        setFormState(RegistrationFormState.WAIT_LIST_PAGE)
        setProgressValue(3)
        return
    }

    const handlePayrollProviderData = async (data: any) => {

        const company = {
            company: {
                payroll_provider_id: data.providerId,
                contact_phone: data.companyNumber,
                admin_contact_phone: data.contactNumber
            }
        }

        if (!data.pinwheelCompatible) {
            setIsBusy(true)
            await CompanyService.updateCompany(company)
            await waitListCompany()
            setIsBusy(false)
            return
        }

        const selfServeState = await CompanyService.checkSelfServe(data.headcount, data.locationCount)

        // If selfServeState === waitlist, set to state, go to waitlist page
        if (selfServeState === SelfServeState.WAIT_LIST) {
            await waitListCompany()
            setFormState(RegistrationFormState.WAIT_LIST_PAGE)
            setProgressValue(0)
            return
        }

        const payCycle = {
            periodStartDate: data.periodStartDate,
            periodEndDate: data.periodEndDate,
            payDate: data.payDate
        }
        try {
            // If selfServeState === sales option, set to state, go to SELF_SERVE_OPTION_PAGE
            // if (selfServeState === SelfServeState.SALES_OPTION) {
            //     setIsBusy(true)
            //     await updateCompany(payCycle, company, RegistrationStatus.SELF_SERVE_OPTION_STATE)
            //     setPayPeriod({periodStart: data.periodStartDate, periodEnd: data.periodEndDate})
            //     setIsBusy(false)
            //     setFormState(RegistrationFormState.SELF_SERVE_OPTION_PAGE)
            //     return
            // }

            setIsBusy(true)
            await updateCompany(payCycle, company, RegistrationStatus.SYSTEMS_CHECK_STATE)
            setPayPeriod({periodStart: data.periodStartDate, periodEnd: data.periodEndDate})
            setIsBusy(false)
            setFormState(RegistrationFormState.SYSTEMS_CHECK_PAGE_ONE)
            setProgressValue(2)
            return

        } catch (e) {
            console.error(e)
        }
    }

    async function updateCompany(payCycle: any, company: any, state: string) {
        await CompanyService.updateCompanyPayCycle(payCycle)
        await CompanyService.updateCompany(company)
        await CompanyService.updateCloverRegistration(state)
    }

    // const handleSelfServeChoice = async (data: any) => {
    //     if (data.choice === 'true') {
    //         await CompanyService.updateCloverRegistration(RegistrationStatus.SYSTEMS_CHECK_STATE)
    //         setFormState(RegistrationFormState.SYSTEMS_CHECK_PAGE_ONE)
    //         setProgressValue(2)
    //         return
    //     }
    //     setFormState(RegistrationFormState.CALENDAR_PAGE)
    //     setProgressValue(1)
    //     return
    // }

    const handleScheduleCall = async (data: any) => {
        if (data.success) {
            setIsBusy(true)
            await CompanyService.waitListCompany(RegistrationStatus.CALL_LIST)
            setIsBusy(false)
            setFormState(RegistrationFormState.SCHEDULED_COMPLETE_PAGE)
            setProgressValue(3)
            return
        }
    }

    const handleGoBack = async () => {
        if (formState === RegistrationFormState.SYSTEMS_CHECK_PAGE_ONE) {
            await CompanyService.updateCloverRegistration(RegistrationStatus.INFORMATION_STATE)
            setFormState(RegistrationFormState.INFORMATION_PAGE)
            setProgressValue(1)
            return
        } else if (formState === RegistrationFormState.CONFIRMATION_PAGE ||
            formState === RegistrationFormState.SYSTEMS_CHECK_PAGE_TWO) {
            await CompanyService.updateCloverRegistration(RegistrationStatus.SYSTEMS_CHECK_STATE)
            setFormState(RegistrationFormState.SYSTEMS_CHECK_PAGE_ONE)
            setProgressValue(2)
            return
        } else if (formState === RegistrationFormState.SYSTEMS_TIME_AND_ATTENDANCE) {
            setFormState(RegistrationFormState.SYSTEMS_CHECK_PAGE_ONE)
            setProgressValue(2)
            return
        } else if (formState === RegistrationFormState.SELF_SERVE_OPTION_PAGE) {
            await CompanyService.updateCloverRegistration(RegistrationStatus.INFORMATION_STATE)
            setFormState(RegistrationFormState.INFORMATION_PAGE)
            setProgressValue(1)
            return
        } else if (formState === RegistrationFormState.CALENDAR_PAGE) {
            await CompanyService.updateCloverRegistration(RegistrationStatus.SELF_SERVE_OPTION_STATE)
            setFormState(RegistrationFormState.SYSTEMS_CHECK_PAGE_ONE)
            setProgressValue(1)
            return
        }
    }

    const handleSystemsCheckData = async (data: any) => {

        if (data.waitList) {
            await waitListCompany()
            return
        } else {
            //On to CONFIRMATION_PAGE
            await CompanyService.updateCloverRegistration(RegistrationStatus.COMMUNICATIONS_CONFIRMATION_STATE)
            setFormState(RegistrationFormState.CONFIRMATION_PAGE)
            setProgressValue(3)
            return
        }
    }

    const handleContinueToDash = async () => {
        console.log("is this triggering??")
        await CompanyService.updateCloverRegistration(RegistrationStatus.COMPLETE)
        navigate('/dashboard')
    }

    const handleOnComplete = async () => {
        await CompanyService.updateCloverRegistration(RegistrationStatus.SUCCESS_STATE)
        setFormState(RegistrationFormState.SUCCESS_PAGE)
    }

    return (
        <>
            <Row className={"company-info"}>
                <Col className={"mx-sm-2 mx-md-3 mx-lg-4 mx-xl-5"}>
                    <Row className={"my-4"}>
                        <Col xs={4} className={"text-left"}>
                            {/*118x64px*/}
                            <img src={logo} style={{height:'32px', width:'auto'}} alt={"stabl-logo"}/>
                        </Col>
                        <Col xs={1} sm={1} md={2} lg={3} xl={4}/>
                        <Col xs={4} className={"text-left"}>
                            <Row>
                                <div>
                                    <img src={indicatorBar} alt={"indicator-bar"}/>
                                </div>

                            </Row>
                        </Col>
                    </Row>
                    {isBusy ? (
                        <Row className="">
                            <Col className={"py-5"}>
                                <Row className={"mx-3 mb-5"}>
                                    <Col className={"mt-5"}>
                                        <Loading/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ) : (
                        <Row className={""}>
                            <Col className={"py-5"}>
                                <Row className={"mb-0"}>
                                    {formState === RegistrationFormState.INFORMATION_PAGE && (
                                        <InformationForm
                                            onSubmit={handlePayrollProviderData}
                                            providers={payrollProviders}/>
                                    )}
                                    {formState === RegistrationFormState.SYSTEMS_CHECK_PAGE_ONE && (
                                        <SystemsCheckForm
                                            onGoBack={handleGoBack}
                                            payPeriod={payPeriod}
                                            onSubmit={handleSystemsCheckData}/>
                                    )}
                                    {/*{formState === RegistrationFormState.SELF_SERVE_OPTION_PAGE && (*/}
                                    {/*    <SelfServeOption*/}
                                    {/*        onSubmit={handleSelfServeChoice}*/}
                                    {/*        onGoBack={handleGoBack}/>*/}
                                    {/*)}*/}
                                    {formState === RegistrationFormState.CALENDAR_PAGE && (
                                        <ScheduleCall
                                            onSubmit={handleScheduleCall}
                                            onGoBack={handleGoBack}/>
                                    )}
                                    {formState === RegistrationFormState.SCHEDULED_COMPLETE_PAGE && (
                                        <ScheduleComplete/>
                                    )}
                                    {formState === RegistrationFormState.WAIT_LIST_PAGE && (
                                        <WaitList/>
                                    )}
                                    {formState === RegistrationFormState.SYSTEMS_CHECK_PAGE_TWO && (
                                        <SystemsCheckFormPinwheel
                                            onGoBack={handleGoBack}/>
                                    )}
                                    {formState === RegistrationFormState.SYSTEMS_TIME_AND_ATTENDANCE && (
                                        <SystemsTimeAndAttendance
                                            onSubmit={handleTAndAProviderData}
                                            onGoBack={handleGoBack}
                                            providers={timeAndAttendanceProviders}/>
                                    )}
                                    {formState === RegistrationFormState.CONFIRMATION_PAGE && (
                                        <CommunicationsConfirmation
                                            onGoBack={handleGoBack}
                                            onComplete={handleOnComplete}/>
                                    )}
                                    {formState === RegistrationFormState.SUCCESS_PAGE && (
                                        <RegistrationSuccess
                                            onSubmit={handleContinueToDash}/>
                                    )}
                                </Row>
                                <Footer/>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </>
    )
}