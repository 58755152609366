import {Button, Col, Form, FormGroup, Input, Label, Progress, Row} from "reactstrap";
import React, {useState} from "react";

export default function SystemsTimeAndAttendance(props: any) {

    const [providerId, setProviderId] = useState('')

    const onSubmit = (e: React.SyntheticEvent) => {

        e.preventDefault()
        props.onSubmit(
            {
                providerId: providerId,
            })
    }

    return (
        <>
            <Row>
                <div className={"mb-5"}>
                    <Progress
                        value={33}
                    />
                </div>
            </Row>
            <Row>
                <div className={"mb-5"}>
                    <Row>
                        <div>
                            <h1>
                                Tell Us About Your Systems
                            </h1>
                        </div>
                    </Row>
                    <Row>
                        <Row>
                            <div className={"mb-5"}><h5>Let's make sure everything is compatible.</h5></div>
                        </Row>
                    </Row>
                </div>
            </Row>
            <Row>
                <Form className={"mt-5 mb-5"} onSubmit={onSubmit}>
                    <Row>
                        <Col md={6}>
                            <Row>
                                <FormGroup>
                                    <Label for="providerSelect">
                                        Time And Attendance
                                    </Label>
                                    <Input
                                        id="providerSelect"
                                        name="select"
                                        type="select"
                                        value={providerId}
                                        onChange={(e) => {
                                            setProviderId(e.target.value);
                                            console.log(e.target.value);
                                        }}
                                    >
                                        <option>
                                            Select Your Provider
                                        </option>
                                        {props.providers.map((provider: any, index: any) => {
                                            return <option key={index} value={provider.id}>
                                                {provider.name}
                                            </option>
                                        })}
                                        <option value={-1}>
                                            Other
                                        </option>
                                    </Input>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup>
                                    <Input
                                        id="providerLoginSelect"
                                        name="select"
                                        type="select"
                                    >
                                        <option>
                                            How Do Employees Log In?
                                        </option>
                                        <option>
                                            1
                                        </option>
                                        <option>
                                            2
                                        </option>
                                        <option>
                                            3
                                        </option>
                                        <option>
                                            4
                                        </option>
                                    </Input>
                                </FormGroup>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={"mt-5 mb-5"}>
                        <Col>
                            <Button className={"mt-5 mb-5"} onClick={props.onGoBack}>
                                {"< Back"}
                            </Button>
                        </Col>
                        <Col>
                            <Button className={"mt-5 mb-5"}>
                                Next
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </>
    )
}