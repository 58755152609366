import {Button, Col, Row} from "reactstrap";
import React from "react";

export default function BackButton(props: any) {

    //TODO -- Figure out ICONS svg

    return (
        <>
            {props.active ? (
                <Row>
                    <Col className="text-left">
                        <Button className={"mt-1 back-button"}
                                onClick={props.onGoBack}>Back
                        </Button>
                    </Col>
                </Row>
            ) : (
                <>
                    <Col xs={1}>
                        &nbsp;
                    </Col>
                </>
            )}

        </>
    )
}