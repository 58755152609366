import React from "react";
import {AccordionBody, AccordionHeader, AccordionItem, Container, Row, UncontrolledAccordion} from "reactstrap";
import AdvanceList from "./advance-list.component";
import {getFormattedDate, isCurrentPayPeriod} from "../../utils/date.utils";


export default function PayoutReport(props: any) {

    function formatPayPeriod(payPeriod: PayPeriod) {
        const start = getFormattedDate(payPeriod.start)
        const end = getFormattedDate(payPeriod.end)
        let stringPeriod = `${start} - ${end}`
        return (isCurrentPayPeriod(payPeriod, props.utcOffset) ? stringPeriod + " (Current)" : stringPeriod)
    }

    return (
        <>
            {props.advancesPresent ? (
                //@ts-ignore
                <UncontrolledAccordion defaultOpen={['0']} stayOpen={true} open={null}>
                    {props.advancePeriods.map((period: any, index: any) => {
                        return (
                            <div key={index} className={"mb-3"}>
                                <AccordionItem id={index.toString()} className={"payout-reports-accordion"}
                                               style={{borderColor: "#E5E7EB"}}>
                                    <AccordionHeader targetId={index.toString()}
                                                     className={"card-payout-report"}>
                                        <p className={"payout-reports-accordion-header-text my-1"}>
                                            <i className={"fa-solid fa-file-lines"}/>&nbsp;&nbsp;{formatPayPeriod(period.payPeriod)}
                                        </p>
                                    </AccordionHeader>
                                    <AccordionBody accordionId={index.toString()}>
                                        <AdvanceList advances={period.advances}/>
                                    </AccordionBody>
                                </AccordionItem>
                            </div>
                        )
                    })}
                </UncontrolledAccordion>

            ) : (
                <>
                    <Container className={"mt-1"}>
                        <div>
                            <Row className={"d-flex align-items-center justify-content-center"}>
                                <img src={"./assets/images/empty-report-icon.png"} alt={"systems-check"}
                                     style={{height: "211.33px", width: "200px"}}/>
                            </Row>
                            <Row className={"mb-5"}>
                                <div className={"payout-reports-empty-text"}>
                                    You don't have any current reports.
                                </div>
                            </Row>
                        </div>
                    </Container>
                </>
            )}
        </>
    )
}